<template>
    <div>
        <v-dialog v-model="showDialogZNS" fullscreen hide-overlay persistent transition="dialog-bottom-transition">
            <v-card>
                <v-toolbar dark color="oee-theme">
                    <v-btn icon dark @click="closePopup">
                        <v-icon>fa-times-circle</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-toolbar-title> Thông báo ZNS tới khách hàng</v-toolbar-title>
                </v-toolbar>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1 white--text"  class="mt-8 mr-2" @click="sendZNS">Gửi tin</v-btn>
                </v-card-actions>
                <v-card-text>
                    <v-container grid-list-xl fluid>
                        <v-layout row wrap>
                            <v-flex lg12 md12 sm12 pa-0>
                                <v-text-field label="ZNS template ID" v-model="template_id"></v-text-field>
                            </v-flex>
                            <v-flex lg12 md12 sm12 pa-0>
                                <v-text-field label="ZNS template data" v-model="template_data"></v-text-field>
                            </v-flex>
                            <v-flex lg12 md12 sm12 pa-0>
                                <v-textarea label="Danh sách số điện thoại (mỗi số 1 dòng)" v-model="mobile_phone_list" rows="20" outlined></v-textarea>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import _ from "lodash";
import configs from "@/configs";
import { mapState } from "vuex";
import { sendSuccessNotice } from "@/commons/utils";
import { request_header } from '@/commons/hpod';
import Vue from 'vue';
export default {
    data() {
        return {
            viewName: "xuat-kho-phieu-xuat-vn",
            showDialogZNS: true,
            mobile_phone_list: '',
            template_id: '',
            template_data: '',
        }
    },
    methods: {
        openDialog() {
            this.showDialogZNS = true;
        },
        closePopup() {
            window.getApp.$emit("APP_NAVIGATE", "Dashboard");
        },
        sendZNS() {
            const header = {
                headers: request_header(this.$store.state.authentication.token)
            };
            var req = {
                'mobile_phone_list': this.mobile_phone_list,
                'template_id': this.template_id,
                'template_data': this.template_data,
            }
            Vue.http.post(`${configs.SYSTEM_TYPE == 'order' ? configs.API : configs.API_DEPOSIT}/webhook/zns/`, req, header).then(function (res) {
                setTimeout(() => {
                    sendSuccessNotice("Gửi tin nhắn thành công!");
                }, 100);
            });
        }
    },
    mounted() {
        this.$root.$emit("REQUEST_ROOT_OPTION", {});
        this.$root.$on("REQUEST_CREATE_DATA", this.openDialog);
    },
    beforeDestroy() {
        this.$root.$off("REQUEST_CREATE_DATA", this.openDialog);
    },
}
</script>